<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p>活动信息</p>
      <el-form class="mt-24" label-width="140px" :model="act_form" :rules="rules" ref="act_form">
        <!-- :toEnable="!showDetails" -->
        <el-form-item label="分享图片" prop="shareImg">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'500*400'"
            :imgsPreview="act_form.shareImg"
            @getImgData="getImgData" 
            
          />
        </el-form-item>
         <el-form-item label="分享标题" prop="shareTitle">
          <el-input
            class="form-input"
            placeholder="请输入分享标题"
            v-model="act_form.shareTitle"
          
          ></el-input>
          <!-- <span v-else>{{act_form.shareTitle}}</span>   v-if="!showDetails" -->
        </el-form-item>
        <el-form-item label="活动名称" prop="activityName">
          <el-input
            class="form-input"
            maxlength="15"
            show-word-limit
            placeholder="请输入活动名称"
            v-model="act_form.activityName"
           
          ></el-input>
          <!-- <span v-else>{{act_form.activityName}}</span>  v-if="!showDetails" -->
        </el-form-item>
        
        <el-form-item label="活动预告开始时间" prop="noticeTime">
          <el-date-picker
            v-if="!showDetails"
            v-model="act_form.noticeTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
          <span v-else>{{act_form.noticeTime}}</span>
        </el-form-item>
        <el-form-item label="活动时间" prop="activityStartTime">
          <el-date-picker
            v-if="!showDetails"
            v-model="act_date"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            range-separator="至"
            start-placeholder="活动开始日期"
            end-placeholder="活动结束日期"
            @change="getDateTime"
            :picker-options="datePickerOptions"
          ></el-date-picker>
          <span v-else>{{act_form.activityStartTime}} 至 {{act_form.activityEndTime}}</span>
        </el-form-item>
        <el-form-item label="活动商品" prop="activityGoodsVO">
          <div v-if="!showDetails">
            <el-button
              type="primary"
              plain
              @click="selectGoods(act_form.activityGoodsVO)"
            >{{act_form.activityGoodsVO&&act_form.activityGoodsVO.length>=1?'重新选择':'添加'}}</el-button>
          </div>
          <el-table
            :class="!showDetails?'mt-24 member-table':'member-table'"
            v-if='act_form.activityGoodsVO.length'
            :data="act_form.activityGoodsVO"
            width="70%"
          >
          <el-table-column :label="item.value" v-for="(item) in tableHeader" :key="item.key">
              <template slot-scope="scope" >
                <el-avatar
                  v-if='item.key == "imgUrl"'
                  :src="picsPath(scope.row.imgUrl)"
                  shape="square"
                  alt="加载失败"
                  :key="scope.row.imgUrl"
                ></el-avatar>
                <template v-else>{{scope.row[item.key]}}</template>
              </template>
            </el-table-column>
            <el-table-column label="抢购价">
              <template slot-scope="scope">
                <div v-if="!showDetails">
                  <el-form-item
                    :prop="'activityGoodsVO.' + scope.$index +'.buyActivityPrice'"
                    :rules="[{validator:(x,y,z)=>validateActivityPrice(x,y,z,scope.row.price),required: true,trigger: 'blur'}]"
                  >
                    <el-input
                      v-model="scope.row.buyActivityPrice"
                      placeholder="输入抢购价"
                      @keyup.native="scope.row.buyActivityPrice = oninput(scope.row.buyActivityPrice)"
                    ></el-input>
                  </el-form-item>
                </div>
                <span v-else>{{scope.row.buyActivityPrice}}</span>
              </template>
            </el-table-column>
            <el-table-column label="活动库存">
              <template slot-scope="scope">
                <div v-if="!showDetails">
                  <el-form-item
                    :prop="'activityGoodsVO.' + scope.$index +'.buyActivityStock'"
                    :rules="[{validator:(x,y,z)=>validateActivityStock(x,y,z,scope.row.stock),required: true,trigger: 'blur'}]"
                  >
                    <el-input
                      v-if="!showDetails"
                      v-model="scope.row.buyActivityStock"
                      placeholder="输入活动库存"
                      @keyup.native="scope.row.buyActivityStock = oninput(scope.row.buyActivityStock,0)"
                    ></el-input>
                  </el-form-item>
                </div>
                <span v-else>{{scope.row.buyActivityStock}}</span>
              </template>
            </el-table-column>
            <el-table-column label="虚拟销量">
              <template slot-scope="scope">
                <div v-if="!showDetails">
                  <el-form-item
                    :prop="'activityGoodsVO.' + scope.$index +'.ficti'"
                    :rules="[{validator:(x,y,z)=>validateActivityVirtual(x,y,z,scope.row.ficti),required: true,trigger: 'blur'}]"
                  >
                    <el-input
                      v-model="scope.row.ficti"
                      placeholder="输入虚拟销量"
                      @keyup.native="scope.row.ficti = oninput(scope.row.ficti, 0)"
                    ></el-input>
                  </el-form-item>
                </div>
                <span v-else>{{scope.row.ficti}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" v-if="!showDetails">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span class="ml-10 tip-text-delete" @click="deleteGoods(scope.$index)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="每人限抢" prop="buyLimit">
          <el-input-number
            v-if="!showDetails"
            class="form-input-200"
            v-model="act_form.buyLimit"
            controls-position="right"
            :min="0"
          ></el-input-number>
          <span v-else>{{act_form.buyLimit}}</span>
          <span class="c-9 ml-15">个</span>
          <!-- <span class="c-9 ml-30">注：输入0代表不限制次数</span> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="cancel">取消</el-button>
      <el-button class="form-button" type="primary" @click="submit">确定</el-button>
    </div>

    <!-- 商品弹窗     @getTableList='getTableList'-->
     <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :selectArr ="afferentArr"
      @getSelectedList="getSelectedList"
    ></DepartmentGoodsDialog>

  </div>
</template>
<script>
import UploadPics from "@/components/UploadPics";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import {subFlashsaleForm, getFlashsaleDetail } from "@/api/maketing/flashsale"
import { getProcudtList} from "@/api/online_department_store/commodity_manage"
export default {
  components: {
    UploadPics,
    DepartmentGoodsDialog
  },
  data() {
    let validateActivityPrice = (rule, value, callback, price) => {
      if (!String(value)) {
        callback(new Error("抢购价不能为空"));
      } else if (isNaN(value) || value < 0) {
        value = "";
        callback(new Error("请输入不小于0的数字"));
      } else if (value > price) {
        callback(new Error("抢购价不能超过实价"));
      } else {
        callback();
      }
    };
    let validateActivityStock = (rule, value, callback, stock) => {
      if (!String(value)) {
        callback(new Error("活动库存不能为空"));
      } else if (isNaN(value) || value < 0) {
        callback(new Error("请输入不小于0的整数"));
      } else if (value > stock) {
        callback(new Error("活动库存不能大于库存"));
      } else {
        callback();
      }
    };

    let validateActivityVirtual = (rule, value, callback) => {
      // // console.log(rule, value, callback);
      if (!String(value)) {
        callback(new Error("虚拟销量不能为空"));
      } else if (isNaN(value) || value < 0) {
        callback(new Error("请输入不小于0的整数"));
      }  else {
        callback();
      }
    };
    return {
      validateActivityPrice,
      validateActivityStock,
      validateActivityVirtual,
      act_form: {
        activityName: "",
        shareTitle: '',
        activityGoodsVO: [],
        noticeTime: "",
        activityStartTime: "",
        activityEndTime: "",
        buyLimit: 1,
        shareImg: ""
      },
      act_date: [],
      buyActivityStock: "sdafdsfa",
      rules: {
        shareImg: [
          { required: true, message: "分享图片不能为空", trigger: "blur" }
        ],
        activityName: [
          { required: true, message: "活动名称不能为空", trigger: "blur" }
        ],
        noticeTime: [
          { required: true, message: "预告时间不能为空", trigger: "blur" }
        ],
        activityStartTime: [
          { required: true, message: "活动时间不能为空", trigger: "blur" }
        ],
        activityGoodsVO: [
          { required: true, message: "活动商品不能为空", trigger: "blur" }
        ],
        buyLimit: [
          { required: true, message: "每人限抢不能为空", trigger: "blur" }
        ],
        shareTitle: [
          { required: true, message: "活动分享标题不能为空", trigger: "blur" }
        ],
      },
      addFlag: true,
      actId: "",
      tableHeader: [  // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode"
        },
         {
          value: "商品id",
          key: "goodsId"
        },
        {
          value: "商品图片",
          key: "imgUrl"
        },
        {
          value: "商品名称",
          key: "goodsName"
        },
        {
          value: "所属门店",
          key: "storeName"
        },
        {
          value: "一级分类",
          key: "firstClassifyName"
        },
        {
          value: "二级分类",
          key: "secondClassifyName"
        },
        {
          value: "实价",
          key: "price"
        },
        {
          value: "库存",
          key: "stock"
        }
      ],
      afferentArr:[], //传到子组件  商品列表
      showDetails: true,
      datePickerOptions: this.limitDate() // 日期范围限制
    };
  },
  mounted() {
    this.handleOpeType();
  },
  methods: {
    oninput(num, flag = 1) {
      let str = num;
      let len1 = str.substr(0, 1);
      let len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      if (flag) {
        //限制只能输入一个小数点
        if (str.indexOf(".") != -1) {
          let str_ = str.substr(str.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
          }
        }
        //正则替换
        str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
        str = str.replace(/\.\d{3,}$/, ""); // 小数点后只能输两位
      } else {
        str = str.replace(/[^\d^]+/g, ""); // 保留数字
      }
      return str;
    },
    // 日期范围限制
    limitDate() {
      return {
        disabledDate: time => {
          const oneDay = 24 * 3600 * 1000;
          return time.getTime() < new Date().getTime() - oneDay;
        }
      };
    },
    //活动编辑态处理
    handleOpeType() {
      let params = this.$route.params;
      this.addFlag =
        params.addFlag != undefined
          ? params.addFlag
          : sessionStorage.getItem("ADD_FLAG");
      this.addFlag === "false" ? (this.addFlag = false) : "";
      this.showDetails =
        params.checkDetails != undefined
          ? params.checkDetails
          : sessionStorage.getItem("CHECK_DETAILS");
      this.showDetails === "false" ? (this.showDetails = false) : "";
      params.addFlag != undefined
        ? sessionStorage.setItem("ADD_FLAG", params.addFlag)
        : "";
      params.checkDetails != undefined
        ? sessionStorage.setItem("CHECK_DETAILS", params.checkDetails)
        : "";
      if (!this.addFlag) {
        this.actId = params.id || sessionStorage.getItem("ACT_ID");
        this.getActData(this.actId);
      }
    },
    //获取活动详情
    getActData(id) {
      getFlashsaleDetail(id).then(res => {
        // console.log("请求结果详情", res);
        this.act_form = res.data.body;
        this.act_date = [
          this.act_form.activityStartTime,
          this.act_form.activityEndTime
        ];
      })
    },
    //从子组件传过来的事件  获取已勾选商品
    getSelectedList(data, index) {
      // // console.log(data)
      this.act_form.activityGoodsVO = data;
    },
    //我改了  查询商品列表   我放到子组件去了
    // getTableList(data){ //获取商品列表
    //   getProcudtList({goodsStatus: 1, ...data}).then(res => {
    //      let data = this.$refs.GoodsDialog;
    //     data.total = res.data.body.total;
    //     data.tableData = res.data.body.list;
    //     //进行状态勾选
    //     // data.toggleSelection() 
    // //    data.defaultSelection(); //默认勾选
    //   });
    // },
     // 打开商品列表弹窗  点击重新选择
    selectGoods(products) {
      //  console.log(products, "products");
      let data = this.$refs.GoodsDialog;
      data.act_form.list = products;
      this.afferentArr = products;
      //  data.selected_temp = JSON.parse(JSON.stringify(products));
      data.showGoods = true;
      data.getGoods(true);
    },
    // 删除已选商品
    deleteGoods(idx) {
      console.log( this.$refs.GoodsDialog.multipleSelection)
      this.act_form.activityGoodsVO.splice(idx, 1);
      this.$refs.GoodsDialog.selected_temp = JSON.parse(
        JSON.stringify(
          this.act_form.activityGoodsVO
        )
      );
       this.afferentArr = JSON.parse(
        JSON.stringify(
          this.act_form.activityGoodsVO
        )
      );
    },
    //获取日期时间
    getDateTime() {
      this.act_form.activityStartTime = this.act_date[0];
      this.act_form.activityEndTime = this.act_date[1];
    },
    getImgData(imgData, type) {
      if (type == "main") this.act_form.shareImg = imgData.mainImg;
    },
    cancel() {
      this.$router.go(-1);
    },
    //预告时间与活动开始时间校验
    timeCheck() {
      let time_predict = new Date(this.act_form.noticeTime).getTime();
      let time_start = new Date(this.act_form.activityStartTime).getTime();
      if (time_predict >= time_start) {
        this.$message.error("活动预告时间应早于活动开始时间");
        return false;
      }
      return true;
    },
    submit() {
      if (!this.timeCheck()) return;
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning"
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.act_form));
      if (String(this.addFlag) == "false") {
        data.id = this.actId;
      }
      data.goodsList = [];
      data.activityGoodsVO.forEach(item => {
        data.goodsList.push({
          buyActivityStock: item.buyActivityStock,
          goodsId: item.goodsId,
          ficti: item.ficti,
          buyActivityPrice:
            item.buyActivityPrice[item.buyActivityPrice.length - 1] === "."
              ? item.buyActivityPrice.substr(
                  0,
                  item.buyActivityPrice.length - 1
                )
              : item.buyActivityPrice
        });
      });
      //  console.log(data, "data");
      subFlashsaleForm(data, String(this.addFlag) == "false" ? 0 : 1).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          this.$router.go(-1);
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.el-avatar /deep/ img {
  width: 100%;
}
</style>