import request from '@/utils/request'

// 限时抢购新建、编辑提交
export function subFlashsaleForm (data, type) { //type 1 新建 0编辑
    return request({
        url: "/small-inteface/buyActivity",
        method: type == 1 ? 'post' : 'put',
        data
    })
}

// 根据Id获取限时抢购详情
export function getFlashsaleDetail (id) {
    return request({
        url:  `/small-inteface/buyActivity/${id}`,
        method: 'get'
    })
}

// 获取限时抢购列表
export function getFlashsaleList (params) {
    return request({
        url:  "/small-inteface/buyActivity",
        method: 'get',
        params
    })
}

// 终止限时抢购
export function stopFlashsale (id) {
    return request({
        url:  "/small-inteface/buyActivity/" + id + "/3",
        method: 'put'
    })
}

//获取限时抢购商品列表
export function getActivityProcudtList (params) {
    return request({
        url:  "/small-inteface/buyActivity/goodsList",
        method: 'get',
        params
    })
}
